import { injectable } from 'inversify';
import { Brand, Carrier } from '../integration/enums';
import { BrandService } from './brandService';
import { PrivacyInformation, VoipInformation, PlanSelectorProductInformation, DefaultPlanSelectorProducts, FeaturesData, HelpOption, LegalDocuments } from './brandServiceInterface';

/**
 * Brand-specific logic for UNI.
 */
@injectable()
export class HispBrandService extends BrandService {
  public getName (): Brand {
    return Brand.HIS;
  }

  public getTitle (): string {
    return 'Harbour ISP';
  }

  public getHeroTitle (): string {
    return 'It\'s time to experience feel-good internet';
  }

  public getHomeAnnouncement (): string {
    return '';
  }

  public getHeroImage (): string {
    return this.getAssetsBaseUrl() + `/${this.getName()}/${this.getName().toLowerCase()}-hero1.png`;
  }

  public getPrivacyInformation (): PrivacyInformation {
    return {
      brand: 'Harbour ISP',
      brandPlural: "Harbour ISP's",
      brandUrl: 'www.harbourisp.com.au',
      policyUrl: 'www.harbourisp.com.au/legal',
      privacyOfficerDetails: {
        email: 'privacy@harbourisp.com.au',
        phone: '1300 070 925',
        businessName: 'Uniti Group Limited',
        address: 'Level 1 / 44 Currie Street, Adelaide SA 5000'
      }
    };
  }

  public getVoipInformation (): VoipInformation {
    return {
      email: 'support@harbourisp.com.au',
      phone: '1300 847 201',
      csaAgreementUrl: 'https://www.unitiwireless.com/pdf/uni/Customer%20Service%20Agreement.pdf'
    };
  }

  public getPlanSelectorProductInformation (carrier?:string): PlanSelectorProductInformation {
    if (carrier === Carrier.NB) {
      return {
        standardFeatures: [
          'No lock-in contract'
        ],
        standardLinks: [
          {
            name: 'Critical Information Summary',
            link: this.getAssetsBaseUrl() + `/${this.getName()}/critical-information-summary-internet-nbn.pdf`
          },
          {
            name: 'Key Fact Sheet',
            link: this.getAssetsBaseUrl() + `/${this.getName()}/key-fact-sheet-nbn.pdf`
          }
        ]
      };
    }
    return {
      standardFeatures: [
        'No lock-in contract'
      ],
      standardLinks: [
        {
          name: 'Critical Information Summary',
          link: this.getAssetsBaseUrl() + `/${this.getName()}/critical-information-summary-internet.pdf`
        }
      ]
    };
  }

  public getDefaultPlanSelectorProducts (): Array<DefaultPlanSelectorProducts> {
    return [{
      id: '',
      name: 'The 25',
      description: 'Perfect for single-person households',
      price: 79,
      typicalEveningSpeed: 24.7,
      download: 25,
      upload: 5
    }, {
      id: '',
      name: 'The 50',
      description: 'Suitable for 2-3 users doing basic browsing',
      price: 95,
      typicalEveningSpeed: 48.4,
      download: 50,
      upload: 20
    }, {
      id: '',
      name: 'The 100',
      description: 'Suitable for 2-3 users on multiple devices at once',
      price: 99,
      typicalEveningSpeed: 96.9,
      download: 100,
      upload: 20
    }, {
      id: '',
      name: 'The 100+',
      description: 'Suitable for regular working from home',
      price: 105,
      typicalEveningSpeed: 96.9,
      download: 100,
      upload: 40
    }, {
      id: '',
      name: 'The 250',
      description: 'Faster off-peak gaming downloads',
      price: 119,
      typicalEveningSpeed: 236,
      download: 250,
      upload: 25
    }, {
      id: '',
      name: 'The 1000',
      description: 'Suitable for simultaneous video conferences',
      price: 129,
      typicalEveningSpeed: 919,
      download: 1000,
      upload: 50
    }];
  }

  public getFeaturesData (): FeaturesData {
    return {
      header: {
        headline: 'We\'ve got your internet covered!',
        tagline: ''
      },
      features: [{
        name: 'Build-to-Rent Specialists',
        description: 'We work closely wiith our partners to deliver a seamless, connected experience in your apartment and building\'s common areas',
        img: this.getAssetsBaseUrl() + `/${this.getName()}/feature1.png`
      }, {
        name: 'Quick & Easy Connection',
        description: 'Harbour ISP has pre-installed a router in your new home, so you can get online the same day.',
        img: this.getAssetsBaseUrl() + `/${this.getName()}/feature2.png`
      }, {
        name: 'Friendly Support',
        description: 'Have an internet issue? Our technical support team is available until 9pm, 7 days a week. Or book an appointment with our local technician.',
        img: this.getAssetsBaseUrl() + `/${this.getName()}/feature3.png`
      }, {
        name: 'Unlimited Data Plans',
        description: 'Browse, stream or game to your heart\'s content! Our wide range of fibre internet plans have no data caps, you just need to decide how fast you want to go.',
        img: this.getAssetsBaseUrl() + `/${this.getName()}/feature4.png`
      }]
    };
  }

  public getSupportPhoneNumber (): string {
    return '1300 579 403';
  }

  public getSalesPhoneNumber (): string {
    return '1300 886 383';
  }

  public getRelocationPhoneNumber (): string {
    return '1300 579 403';
  }

  public getSupportEmail (): string {
    return 'support@harbourisp.com.au';
  }

  public getAddress (): string {
    return 'Level 1 / 44 Currie Street, Adelaide SA 5000';
  }

  public getContacts (): Array<HelpOption> {
    return [{
      name: 'CALL US NOW',
      icon: 'PhoneIcon',
      description: `<div style="height:162px;"><strong>Customer Service</strong><br>Monday – Friday: 8.30am – 6pm AEST<br>Saturday, Sunday & National Public Holidays: Closed<br><br><strong>Technical Support</strong><br>Monday – Friday: 8am – 9pm AEST<br>Saturday & Sunday: 9am – 9pm AEST<br>National Public Holidays: Closed</div><br><br><a class="text-white hover:text-grey-200 hover:underline transition duration-250 bg-brand-primary py-2 px-10 rounded-full text-sm" href="tel:${this.getSalesPhoneNumber()}">CALL US</a> <br><br> <span class="text-sm font-black">${this.getSupportPhoneNumber()}</span>`
    }, {
      name: 'EMAIL US',
      icon: 'MailIcon',
      description: `<div style="height:162px;">Submit your request via our Contact Form or alternatively, email us directly at support@harbourisp.com.au </div><br><br><a class="text-white hover:text-grey-200 hover:underline transition duration-250 bg-brand-primary py-2 px-10 rounded-full text-sm" href="mailto:${this.getSupportEmail()}">EMAIL US</a><br><br> <span class="text-sm font-black">${this.getSupportEmail()}</span>`
    }];
  }

  public getLegalDocuments (): LegalDocuments {
    return {
      termsOfService: [
        {
          name: 'Customer Service Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/customer-service-agreement.pdf`
        },
        {
          name: 'Direct Debit Service Request Agreement',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/direct-debit-service-request-agreement.pdf`
        }
      ],
      policies: [
        {
          name: 'Billing Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/billing-policy.pdf`
        },
        {
          name: 'Financial Hardship Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/financial-hardship-policy.pdf`
        },
        {
          name: 'Financial Hardship Policy Summary',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/financial-hardship-policy-summary.pdf`
        },
        {
          name: 'Privacy Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/privacy-policy.pdf`
        },
        {
          name: 'Fair Use Policy',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/fair-use-policy.pdf`
        }
      ],
      formsAndGuides: [
        {
          name: 'Authorised Representative',
          url: this.getAssetsBaseUrl() + `/${this.getName()}/authorised-representative.pdf`
        }
      ],
      legacy: []
    };
  }
}
