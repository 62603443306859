import { Product } from '@/adapters/catalogAdapterInterface';
import { Service } from '@/adapters/serviceQualificationAdapterInterface';
import { inject, injectable } from 'inversify';
import { CartBuilderInterface } from './integration/builders/cartBuilderInterface';
import { IntegrationFacadeInterface, PaymentError } from './integration/integrationFacadeInterface';
import { Customer, OrderServiceInterface, ValidationError } from './orderServiceInterface';
import { AuthenticationServiceInterface } from './authentication/authenticationServiceInterface';
import { Promotion } from '@uniti-internet/promotions-js-client/dist/utilities/types';
import { CatalogProductOffering } from './integration/clients/types';
import store from '@/store';

@injectable()
export class OrderService implements OrderServiceInterface {
  constructor (
    @inject('IntegrationFacadeInterface') private integrationFacade: IntegrationFacadeInterface,
    @inject('AuthenticationServiceInterface') private authenticationService: AuthenticationServiceInterface
  ) { }

  public async submitOrderToShoppingCart (service: Service, products: Array<Product>, customer: Customer, validPromotion?: Promotion, cartId?: string): Promise<string> {
    const cartBuilder = await this.buildCart(service, products, customer, validPromotion, cartId);

    try {
      // Submit cart.
      const cart = cartBuilder.returnCart();
      const cartResult = await this.integrationFacade.saveCart(cart);
      if (!cartResult.id) {
        throw new ValidationError('Sorry, an error occurred. Please refresh the page and try again.');
      }
      return cartResult.id;
    } catch (error) {
      console.error(error);
    }

    // Fall through error.
    throw new ValidationError('Sorry, an error occurred. Please refresh the page and try again.');
  }

  public async submitOrderToShoppingCartWithPayment (service: Service, products: Array<Product>, customer: Customer, validPromotion?: Promotion, cartId?: string): Promise<string> {
    const cartBuilder = await this.buildCart(service, products, customer, validPromotion, cartId);

    try {
      // Submit cart.
      const cart = cartBuilder.returnCart();
      let cartResult = await this.integrationFacade.saveCart(cart);
      if (!cartResult.id) {
        throw new ValidationError('Sorry, an error occurred. Please refresh the page and try again.');
      }

      // Save the cart again, with payment details. Do this only
      // if payment details have been provided.
      if (customer.ccName && customer.ccNumber && customer.ccExpiry && customer.ccCvc) {
        // Save payment.
        const token = await this.integrationFacade.savePaymentToCart(cartResult.id, customer.ccName, customer.ccNumber || 0, customer.ccExpiry, customer.ccCvc);
        if (!token) {
          throw new ValidationError('Sorry, an error occurred. Please refresh the page and try again.');
        }

        cartBuilder.addContactMedium('CardName', {
          socialNetworkId: customer.ccName
        }).addContactMedium('CardToken', {
          socialNetworkId: token
        }).addContactMedium('CardExpiry', {
          socialNetworkId: customer.ccExpiry
        });
      }

      cartBuilder.complete();
      cartResult = await this.integrationFacade.saveCart(cart);
      return cartResult.id || '';
    } catch (error) {
      console.error(error);
      if (error instanceof PaymentError) {
        throw new ValidationError(`A payment error occurred: ${error.message}.`);
      }
    }

    // Fall through error.
    throw new ValidationError('Sorry, an error occurred. Please refresh the page and try again.');
  }

  public calculateActivationFee (products: Array<Product>): number {
    const fees = products.filter(product => product.type === 'fee' && product.name !== 'New Development Cost');

    let total = 0;
    for (const fee of fees) {
      total += fee.price;
    }

    return total;
  }

  public calculateFirstInvoiceTotal (products: Array<Product>, isPromoValid: boolean, validPromotion?: Promotion): number {
    let total = 0;
    let yearOffAction, monthOffAction;

    if (validPromotion) {
      yearOffAction = validPromotion.actions.find(action => action.actionCatalogObject.code === 'YEAR-OFF');
      monthOffAction = validPromotion.actions.find(action => action.actionCatalogObject.code === 'ONE-OFF');
    }

    for (const product of products) {
      if (isPromoValid && product.type === 'internet' && (yearOffAction || monthOffAction)) {
        total += 0;
      } else if (isPromoValid && product.discountedPrice !== undefined) {
        total += product.discountedPrice;
      } else {
        total += product.price;
      }
    }

    return total;
  }

  public calculateMinimumCost (products: Array<Product>, isPromoValid: boolean, validPromotion?: Promotion): number {
    let total = 0;
    let delayedNdcAction, yearOffAction, monthOffAction;

    if (validPromotion) {
      delayedNdcAction = validPromotion.actions.find(action => action.actionCatalogObject.code === 'DELAYED-NDC');
      yearOffAction = validPromotion.actions.find(action => action.actionCatalogObject.code === 'YEAR-OFF');
      monthOffAction = validPromotion.actions.find(action => action.actionCatalogObject.code === 'ONE-OFF');
    }

    const fee = products.find(product => {
      return product.type === 'fee' && product.name !== 'New Development Cost';
    });

    let term = 1;
    if (fee && fee.term) {
      term = fee.term;
    }

    for (const product of products) {
      // We add the normal NDC cost to the minimum cost when the NDC is
      // delayed as the customer will pay it at the end of the contract
      // term.
      if (product.code === 'NDC' && delayedNdcAction && isPromoValid) {
        total += product.price;
        continue;
      }
      // If we have a year-off discount we calculate the price by reducing
      // the term.
      if (product.type === 'internet' && yearOffAction && isPromoValid) {
        if (term === 24) term = 12;
        if (term === 12) term = 0;
        if (product.discountedPrice) {
          total += product.discountedPrice * term;
        }
        continue;
      }

      if (product.type === 'fee' || product.type === 'hardware') {
        if (isPromoValid && product.discountedPrice !== undefined) {
          total += product.discountedPrice;
        } else {
          total += product.price;
        }
      } else {
        if (isPromoValid && product.discountedPrice !== undefined) {
          if (monthOffAction) {
            total += product.discountedPrice * (term - 1);
          } else {
            total += product.discountedPrice * term;
          }
        } else {
          total += product.price * term;
        }
      }
    }

    return total;
  }

  private async buildCart (service: Service, products: Array<Product>, customer: Customer, validPromotion?: Promotion, cartId?: string): Promise<CartBuilderInterface> {
    // Get the original SQ.
    const serviceQualification = await this.integrationFacade.doServiceQualification(service.id, null, null, null);

    // Ensure non-optional values exist.
    if (customer.phoneNumber === null) {
      throw new ValidationError('Phone number is required.');
    }

    // Build a cart object based on the selected address, plan and hardware.
    const cartBuilder = this.integrationFacade.createCartBuilder(cartId)
      .setFirstName(customer.firstName)
      .setLastName(customer.lastName)
      .setAddressFromServiceQualification(serviceQualification[0])
      .setEmail(customer.email)
      .setPhone(customer.phoneNumber.toString())
      .setDealer(service.carrier)
      .setLocation(service.locationId)
      .setPreorder(service.development)
      .setTechnology(service.technology)
      .setTotalPrice(this.calculateFirstInvoiceTotal(products, Boolean(validPromotion)))
      .setActivationDate(customer.activationDate)
      .setCrmId(customer.crmId);

    // If there's an account logged in, set it as the sales person.
    const account = await this.authenticationService.getAccount();
    if (account) {
      cartBuilder.setSalesPerson(account.email.toLowerCase());
    }

    if (store.state.salesPerson) {
      cartBuilder.setSalesPerson(store.state.salesPerson.toLowerCase());
    }

    // If it's a business account.
    if (customer.abnNumber !== null && customer.companyName !== null) {
      cartBuilder.setAbn(customer.abnNumber.toString())
        .setCompany(customer.companyName);
    }

    // Get the contract product as we need this to continue.
    const contract = products.find(o => o.type === 'fee' && o.code !== 'NDC');
    if (!contract || contract.term == null) {
      throw new ValidationError('Sorry, an error occurred. Please refresh the page and try again.');
    }

    // Add contract.
    const catalogActivationFee = (await this.integrationFacade.getFeesForServiceQualification(serviceQualification[0])).find(o => o.id === contract.id);
    if (!catalogActivationFee) {
      throw new ValidationError('Sorry, an error occurred. Please refresh the page and try again.');
    }
    cartBuilder.addActivationFee(catalogActivationFee);

    // Add new development cost.
    if (service.requiresNewDevelopmentCost) {
      const newDevelopmentCost = products.find(o => o.type === 'fee' && o.name === 'New Development Cost');
      if (newDevelopmentCost) {
        // Get NDC product from catalog.
        const newDevelopmentCostProduct = (await this.integrationFacade.getFeesForServiceQualification(serviceQualification[0])).find(o => o.id === newDevelopmentCost.id);

        // Add it.
        if (newDevelopmentCostProduct) {
          cartBuilder.addNewDevelopmentCost(newDevelopmentCostProduct);
        }
      }
    }

    // Add each product to the cart API object.
    for (const product of products) {
      if (product.type === 'internet') {
        const catalogProduct = (await this.integrationFacade.getPlansForServiceQualification(serviceQualification[0])).find(o => o.id === product.id);
        if (catalogProduct) {
          cartBuilder.addPlan(catalogProduct, contract.term);
        }
      } else if (product.type === 'hardware') {
        const catalogProduct = (await this.integrationFacade.getHardwareForServiceQualification(serviceQualification[0])).find(o => o.id === product.id);
        if (catalogProduct) {
          cartBuilder.addHardware(catalogProduct);
        }
      } else if (product.type === 'voice') {
        const catalogProduct = (await this.integrationFacade.getVoiceForServiceQualification(serviceQualification[0])).find(o => o.id === product.id);
        if (catalogProduct) {
          cartBuilder.addVoicePlan(catalogProduct);
        }
      }
    }

    // If we have a validPromotion we add the promotion products to the cart
    if (validPromotion) {
      const promotionCatalogOfferings = await this.integrationFacade.getPromotionsForServiceQualification(serviceQualification[0]);

      // Loop through the promotion actions
      for (const action of validPromotion.actions) {
        let matchedProduct: Product|undefined;

        // Find the corresponding catalog product for the promotion action
        const promotionProduct: CatalogProductOffering|undefined = promotionCatalogOfferings.find(product => product.id === action.actionCatalogObject.id);

        // If the promotion product is 'Free Activation' we adjust the price to reduce the activation
        // fee amount
        if (action.name === 'Free Activation') {
          const activationFeePrice = cartBuilder.getActivationFeePrice();
          if (activationFeePrice && promotionProduct) {
            promotionProduct.productOfferingPrice[0].price.amount = -activationFeePrice;
          }
        }

        // If the promotion product is 'ONE-OFF' we adjust price to match the plan price
        if (action.actionCatalogObject.code === 'ONE-OFF' || action.actionCatalogObject.code === 'YEAR-OFF') {
          const planPrice = cartBuilder.getPlanPrice();
          if (planPrice && promotionProduct) {
            promotionProduct.productOfferingPrice[0].price.amount = -planPrice;
          }
        }

        if (action.applicableWhen.length > 0) {
          let filteredProducts: Product[] = [];
          for (const id of action.applicableWhen) {
            filteredProducts = products.filter(product => product.id === id);
          }
          if (filteredProducts.length === 0) {
            continue;
          }
        }

        if (action.applicableTo.length > 0) {
          // Check the action applicableTo ID's to see if it matches a product.
          for (const applicableId of action.applicableTo) {
            matchedProduct = products.find(product => product.id === applicableId);

            if (matchedProduct) {
              // If the promotion product CODE is 'FREE-ROUTER' we adjust price to match the hardware price
              if (action.actionCatalogObject.code === 'FREE-ROUTER') {
                const routerPrice = cartBuilder.getHardwarePrice();
                if (routerPrice && promotionProduct) {
                  promotionProduct.productOfferingPrice[0].price.amount = -routerPrice;
                }
              }

              // If the promotion code is 'ROUTER-DISCOUNT' we adjust price we discount using the action price
              // as a % of the original price
              if (action.actionCatalogObject.code === 'ROUTER-DISCOUNT') {
                const routerPrice = cartBuilder.getHardwarePrice();
                if (routerPrice && promotionProduct) {
                  promotionProduct.productOfferingPrice[0].price.amount = this.calculatePercentageOffPrice(routerPrice, action.actionCatalogObject.price);
                }
              }

              // We set the product term for discounts with a duration
              if (promotionProduct) {
                cartBuilder.addPromotion(promotionProduct, validPromotion.code);
                cartBuilder.setProductTerm(action.duration, promotionProduct.name);
              }
            }
          }
        // If applicableTo is empty we assume the promotion should be applied regardless.
        } else if (action.applicableTo.length === 0) {
          if (promotionProduct) {
            cartBuilder.addPromotion(promotionProduct, validPromotion.code);
            cartBuilder.setProductTerm(action.duration, promotionProduct.name);
          }
        }
      }
    }

    return cartBuilder;
  }

  private calculatePercentageOffPrice (price: number, percentage: number) {
    return price - price * Math.abs(percentage) / 100;
  }
}
